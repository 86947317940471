import * as React from "react"
import { Header, Box, ResponsiveContext, Menu, Button } from "grommet"
import { Menu as MenuIcon } from "grommet-icons"
import { Link } from "gatsby"

const HeaderComponent = ({ nav }) => {
  const size = React.useContext(ResponsiveContext)
  if (!size) return null

  return (
    <Header
      background="transparent"
      pad={{ horizontal: "large" }}
      height="xsmall"
    >
      <Box />
      {size === "small" ? (
        <Box justify="end">
          <Menu
            a11yTitle="Navigation Menu"
            dropProps={{
              align: { top: "bottom", right: "right" },
              elevation: "small",
            }}
            icon={<MenuIcon color="brand" />}
            items={nav.map(({ label, href }) => ({
              label: (
                <Box pad="medium">
                  <Link to={href}>{label}</Link>
                </Box>
              ),
            }))}
          />
        </Box>
      ) : (
        <Box justify="end" direction="row" gap="large">
          {nav.map(({ label, href }) => (
            <Link to={href} key={label}>
              <Button primary label={label} />
            </Link>
          ))}
        </Box>
      )}
    </Header>
  )
}

export default HeaderComponent
