/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Sidebar from "./sidebar"

import {
  Box,
  Grid as GrommetGrid,
  grommet,
  Grommet,
  Main,
  Paragraph,
  ResponsiveContext,
} from "grommet"
import styled from "styled-components"
import { deepMerge } from "grommet/utils"

const Footer = styled(Box)`
  margin-bottom: 20px;
`

const customTheme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#abb3fa",
      focus: "#ffd987",
    },
  },
})

const rows = {
  small: ["xsmall", "xsmall", "auto", "auto"],
  medium: ["xsmall", "auto", "auto"],
}

const columns = {
  small: ["auto"],
  medium: ["small", "auto", "xxsmall"],
}

const gridAreas = {
  small: [
    { name: "header", start: [0, 0], end: [0, 0] },
    { name: "sidebar", start: [0, 1], end: [0, 1] },
    { name: "content", start: [0, 2], end: [0, 2] },
    { name: "footer", start: [0, 3], end: [0, 3] },
  ],
  medium: [
    { name: "header", start: [0, 0], end: [2, 0] },
    { name: "sidebar", start: [0, 0], end: [0, 1] },
    { name: "content", start: [1, 1], end: [2, 1] },
    { name: "footer", start: [2, 2], end: [2, 2] },
  ],
}

const Grid = ({ children }) => {
  let size = React.useContext(ResponsiveContext)

  if (size === "large") size = "medium"

  return (
    <GrommetGrid
      width="100%"
      rows={rows[size]}
      columns={columns[size]}
      gap="small"
      areas={gridAreas[size]}
    >
      {children}
    </GrommetGrid>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          nav {
            label
            href
          }
        }
      }
    }
  `)

  return (
    <Grommet theme={customTheme} background="light-1" full>
      <Grid>
        <Box gridArea="header" style={{ position: "sticky", top: 0 }}>
          <Header nav={data.site.siteMetadata?.nav || `Title`} />
        </Box>
        <Box gridArea="sidebar">
          <Sidebar />
        </Box>
        <Box gridArea="content">
          <Main pad={{ horizontal: "medium" }} flex="shrink">
            {children}
          </Main>
        </Box>

        <Footer flex="shrink" alignSelf="end" gridArea="footer">
          <Paragraph style={{ writingMode: "vertical-rl" }} fill>
            © {new Date().getFullYear()} philip zhang
          </Paragraph>
        </Footer>
      </Grid>
    </Grommet>
  )
}

export default Layout
