import * as React from "react"
import { Box, Heading, ResponsiveContext } from "grommet"
import styled from "styled-components"
import { Link } from "gatsby"

const SidebarContainer = styled(Box)`
  position: sticky;
  top: 0;
  height: fit-content;
`

const Sidebar = () => {
  const size = React.useContext(ResponsiveContext)
  return (
    <SidebarContainer background="transparent" pad="large">
      <Link to="/">
        <Heading level="1">
          philip
          {size !== "small" ? <br /> : " "}
          zhang
        </Heading>
      </Link>
    </SidebarContainer>
  )
}

export default Sidebar
